import React from "react"
import scrollTo from "gatsby-plugin-smoothscroll"
// import resume from "../images/resume.pdf"
const Navbar = () => {
  return (
    <div className="section">
      <div className="container">
        <div className="navbar-wrapper">
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
          <div
            role="button"
            onClick={() => scrollTo("#home")}
            className="name"
            tabIndex={0}
          >
            Portfolio.
          </div>
          <div className="links-wrapper">
            <button onClick={() => scrollTo("#work")}>Work</button>
            <button onClick={() => scrollTo("#about")}>About</button>
            {/* <a href={resume} without rel="noopener noreferrer" target="_blank">
                <button trailingIcon="picture_as_pdf" label="Resume">
                  Resume
                </button>
            </a> */}
            <button onClick={() => scrollTo("#contact")}>Contact</button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Navbar
